import ApexCharts from "apexcharts";

export default function generateQuarterlyChartOt({
  totals,
  partTimeTotals,
  title1 = "Full Time Employees",
  title2 = "Part Time Employees",
  div,
  color1 = "#1A56DB",
  color2 = "#FDBA8C",
}: {
  totals: any;
  partTimeTotals: any;
  title1: string;
  title2: string;
  div: string;
  color1?: string;
  color2?: string;
}) {
  const options2 = {
    colors: [color1, color2],
    series: [
      {
        name: title1,
        color: color1,
        data: [
          { x: "Q1", y: (totals[1]) },
          { x: "Q2", y: (totals[2]) },
          { x: "Q3", y: (totals[3]) },
          { x: "Q4", y: (totals[4]) },
        ],
      },
      {
        name: title2,
        color: color2,
        data: [
          { x: "Q1", y: (partTimeTotals[1]) },
          { x: "Q2", y: (partTimeTotals[2]) },
          { x: "Q3", y: (partTimeTotals[3]) },
          { x: "Q4", y: (partTimeTotals[4]) },
        ],
      },
    ],
    chart: {
      type: "bar",
      height: "320px",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        borderRadiusApplication: "end",
        borderRadius: 8,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontFamily: "Inter, sans-serif",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    stroke: {
      show: true,
      width: 0,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: -14,
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
    },
    xaxis: {
      floating: false,
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
    },
    fill: {
      opacity: 1,
    },
  };

  if (document.getElementById(div) && typeof ApexCharts !== "undefined") {
    const chart2 = new ApexCharts(document.getElementById(div), options2);
    chart2.render();
  } else {
    console.log("Column Chart not found");
  }
}
