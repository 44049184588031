import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import Menu from "../layout/menu";
import { HexColorPicker } from "react-colorful";
import generateQuarterlyChartOt from "./quarterly-chart-ot";
import generateChart from "./chart";

export default function CreateChart() {
  const [data, setData] = useState<any[]>([]);
  const [val1, setVal1] = useState("2019");
  const [val2, setVal2] = useState("65");
  const [val3, setVal3] = useState("30");
  const [val4, setVal4] = useState("40");
  const [val12, setVal12] = useState("50");
  const [val22, setVal22] = useState("60");
  const [val32, setVal32] = useState("70");
  const [val42, setVal42] = useState("80");
  const [title1, setTitle1] = useState("Admissions");
  const [title2, setTitle2] = useState("2020");
  const [color, setColor] = useState("#1A56DB");
  const [color2, setColor2] = useState("#FDBA8C");

  function addRow() {
    const newData = [...data];
    newData.push({ x: val1, y: val2 });
    setData(newData);
  }
  function removeRow() {
    const newData = [...data];
    newData.pop();
    setData(newData);
  }

  function createChart() {
    const totals = [];
    totals[1] = parseInt(val1);
    totals[2] = parseInt(val2);
    totals[3] = parseInt(val3);
    totals[4] = parseInt(val4);
    const partTimeTotals = [];
    partTimeTotals[1] = parseInt(val12);
    partTimeTotals[2] = parseInt(val22);
    partTimeTotals[3] = parseInt(val32);
    partTimeTotals[4] = parseInt(val42);
    // const data = [
    //   { x: "2019", y: 88 },
    //   { x: "2020", y: 178 },
    //   { x: "2021", y: 110 },
    // ]
    const options = {
      data,
      title: title1,
      div: "column-chart1",
      color: color,
    };
    generateChart(options);
  }

  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
          <Menu />
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl tracking-widest text-white">
                Create Chart
              </h1>
            </div>
          </header>
        </div>
        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 min-h-96 ">
              <div className="grid grid-cols-3 gap-5">
                <div className="mt-5 col-span-3">
                  <div>
                    <label
                      htmlFor="title1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title
                    </label>
                    <input
                      value={title1}
                      onChange={(e) => setTitle1(e.target.value)}
                      type="text"
                      name="title1"
                      id="title1"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  {/* Button to add a new row */}
                  <div className="mt-5 grid grid-cols-3 gap-5">
                    <div>
                      <label
                        htmlFor="title1"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Row Title
                      </label>
                      <input
                        value={val1}
                        onChange={(e) => setVal1(e.target.value)}
                        type="text"
                        name="title1"
                        id="title1"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="title1"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Value
                      </label>
                      <input
                        value={val2}
                        onChange={(e) => setVal2(e.target.value)}
                        type="text"
                        name="title1"
                        id="title1"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div className="mt-auto">
                      <button
                        onClick={addRow}
                        id="btnCompare"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        Add Row
                      </button>
                      <button
                        onClick={removeRow}
                        id="btnCompare"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                      >
                        Remove Row
                      </button>
                    </div>
                  </div>
                  {/* Display rows as table */}
                  <div className="px-4 sm:px-6 lg:px-8">
      
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Title
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((person, idx) => (
                    <tr key={idx}>
                      
                        <td  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.x}</td>
                        <td  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.y}</td>
                      
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

                  <div className="grid grid-cols-1 mt-5">
                    <div>
                      <label
                        htmlFor="q42"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Color
                      </label>
                      <HexColorPicker color={color} onChange={setColor} />
                    </div>
                  </div>

                  <button
                    onClick={createChart}
                    id="btnCompare"
                    className="mt-5 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  >
                    Create Chart
                  </button>

                  <div className="p-2">
                    <div id="column-chart1" />

                    <div id="column-chart3" />
                    <div id="column-chart4" />
                    <div id="column-chart5" />
                    <div id="column-chart6" />
                    <div id="column-chart7" />
                    <div id="column-chart2" />

                    <div className="h-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
