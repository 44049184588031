import React, { useEffect, useRef, useState } from 'react'
import { downloadURI } from '../utilities/download';
import Radio from '../layout/radio';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Toggle from '../layout/toggle';
import MonthlyChart from './monthly-chart';
import QuarterlyChart from './quarterly-chart';
import { usePapaParse } from 'react-papaparse';
import { getTotalsFromCSV } from '../utilities/get-totals-from-csv';
import generateMonthlyChart from './monthly-chart';
import generateQuarterlyChart from './quarterly-chart';
import { v4 } from "uuid";
import Menu from '../layout/menu';
interface Totals {
    [name: string]: number
}
const url = (process.env.NODE_ENV === "development" ? "http://localhost:4567" : "https://process-payroll-api.clearerc.com") + "/download";

export default function Dashboard() {
    const [downloadType, setDownloadType] = React.useState(3);
    const [customer, setCustomer] = React.useState(1);
    const [isPaused, setPause] = useState(false);
    const [files, setFiles] = useState<string[]>([]);
    const [statusMessages, setStatusMessages] = useState<string[]>([]);
    const [wsOpen, setWsOpen] = useState(false);
    const [daysCovered, setDaysCovered] = useState<number[]>([]);
    const [daysCoveredCount, setDaysCoveredCount] = useState<number[]>([]);
    const [showDebug, setShowDebug] = useState(false);
    const [totalsData, setTotalsData] = useState<Totals>({});
    const [partTimeData, setPartTimeData] = useState<Totals>({});
    const [chartsReady, setChartsReady] = useState(false);
    const [filename, setFilename] = useState(v4());
    const { readRemoteFile } = usePapaParse();
    // const ws = useRef(null);

    const ws = useRef<WebSocket | null>(null);


    useEffect(() => {
        const daysIn = [];
        for (let i = 0; i < 365; i++) {
            if (daysCovered.includes(i)) {
                daysIn[i] = 1;
            } else {
                daysIn[i] = 0;
            }
        }
        setDaysCoveredCount(daysIn);
    }, [daysCovered])

    useEffect(() => {
        if (!ws.current) {
            ws.current = new WebSocket(process.env.NODE_ENV === "development" ? "ws://localhost:4567" : "wss://process-payroll-api.clearerc.com");
            ws.current.onopen = () => {
                console.log("ws opened")
                setWsOpen(true);
                ws.current?.send(JSON.stringify({ token: localStorage.getItem("token") }));
            };
            ws.current.onclose = () => {
                setWsOpen(false);
                console.log("ws closed")
            };
        }

        const wsCurrent = ws.current;

        return () => {
            if (wsCurrent) {
                wsCurrent.close();
            }
        };

    }, []);

    useEffect(() => {
        if (ws.current) {
            ws.current.onmessage = e => {
                if (isPaused) return;
                const message = JSON.parse(e.data);
                console.log("e", message);
                const status2 = document.getElementById("status2");
                if (status2) {
                    if (message.msg) {
                        // status2.append(message.msg+"<br />");
                        setStatusMessages(c => [...c, message.msg])
                    } else if (message.filename) {
                        // alert(message.filename)
                        // setFilename(message.filename)
                    } else if (message.file) {
                        setFiles(c => [...c, message.file])
                        // status2.append(message.file+"<br />");
                        setStatusMessages(c => [...c, message.file])
                    } else if (message.daysIn) {
                        // alert(JSON.stringify(message.daysIn));
                        console.log(message.daysIn)
                        let newDays: number[] = [];

                        for (let i = message.daysIn.startDaysIn; i < message.daysIn.endDaysIn; i++) {

                            newDays.push(i);
                        }
                        setDaysCovered(c => [...c, ...newDays]);
                    } else if (message.y) {
                        // alert(JSON.stringify(message.y));
                    }

                }
            };
        }
    }, [daysCovered, isPaused]);

    // Download a file form a url.
    function dateFromDay(year: number, day: number) {
        var date = new Date(year, 0); // initialize a date in `year-01-01`
        return new Date(date.setDate(day)); // add the number of days
    }

    function saveFile() {
        // var start = new Date().getTime();
        setFiles([]);
        setStatusMessages([]);
        // setFilename("")
        var xmlHttp = new XMLHttpRequest();
        // alert(process.env.REACT_APP_API_SERVER);
        xmlHttp.open("GET", (process.env.NODE_ENV === "development" ? "http://localhost:4567" : "https://process-payroll-api.clearerc.com") + "/getAll?customer=" + customer + "&type=" + (downloadType == 0 ? "PayDate" : downloadType == 1 ? "PayPeriod" : downloadType == 2 ? "Split1" : "Split2") + "&filename=" + filename, true);
        xmlHttp.setRequestHeader("Cache-Control", "no-cache");
        xmlHttp.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("token"));

        xmlHttp.addEventListener("progress", function (evt) {
            console.log(evt);
            // console.log(evt.currentTarget?.responseText);
            const status = document.getElementById("status");
            if (status) {
                const current: any = (evt.currentTarget);
                status.innerHTML = current.responseText;
            }
            var objDiv = document.getElementById("status");
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }

        });
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState == 4) {
                // var total = new Date() - start;
                // console.log;
                // alert(process.env.REACT_APP_API_SERVER);
                // alert(filename)
                // readRemoteCSVFile((process.env.NODE_ENV==="development"?"http://localhost:4567":"https://process-payroll-api.clearerc.com")+"/download");
                readRemoteFile(url + "?filename=" + filename, {
                    download: true,
                    complete: (results) => {
                        console.log('---------------------------');
                        console.log('Results:', results);
                        console.log('---------------------------');
                        // alert(customer)
                        const { totals, partTimeTotals } = getTotalsFromCSV({ csvData: results.data as unknown[] as string[][] , customer});
                        setTotalsData(totals);
                        setPartTimeData(partTimeTotals);
                        setChartsReady(true);
                        generateMonthlyChart({ totals, partTimeTotals });
                        generateQuarterlyChart({ totals, partTimeTotals, title1: "Full Time Employees", title2: "Part Time Employees" });
                    },
                });
                const btn = document.getElementById("btn");
                if (btn) {
                    btn.innerHTML = "Process Payroll";
                }
                // document.getElementById("btn").innerHTML =
                // "Download Processed Payroll";
            } else {
                console.log(xmlHttp.readyState);
            }
        };
        xmlHttp.send(null);
    }

    function download() {
        const btn = document.getElementById("btn");
        if (btn) {
            btn.innerHTML = "Processing...";
        }
        saveFile();
    }
    return (

        <>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <div className="min-h-full">
                <div className="bg-indigo-600 pb-32">
                    <Menu />
                    <header className="py-10">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl tracking-widest text-white">
                                Process Payroll
                                {wsOpen ?
                                    <><CheckIcon className='h-8 w-8 inline-block text-green-500' /> <span className="text-sm">Connected</span></>
                                    :
                                    <><XMarkIcon className='h-8 w-8 inline-block text-red-500' /> <span className="text-sm">Not Connected</span></>
                                }
                            </h1>
                        </div>

                    </header>
                </div>
                <main className="-mt-32">
                    <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">

                            <div className="grid grid-cols-3 gap-5">
                                <div className='mt-5 col-span-3'>

                                    <Radio title="Process Type" options={[
                                        { id: 0, title: 'Pay Date Month', description: 'Uses month from the pay date', users: '621 users' },
                                        { id: 1, title: 'Pay Period Month', description: 'Uses month from the period end', users: '621 users' },
                                        { id: 2, title: 'Split Pay Period One Week', description: 'Splits percentages based on dates', users: '621 users' },
                                        { id: 3, title: 'Split Pay Period Two Weeks', description: 'Splits percentages based on dates', users: '621 users' },
                                    ]
                                    }
                                        value={downloadType || 0
                                        }
                                        onChange={(option) => {
                                            console.log(option);
                                            setDownloadType(option.id);
                                        }
                                        }
                                    />
                                </div>
                                <div>
                                    <div>
                                        <label
                                            htmlFor="location"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            Customer
                                        </label>
                                        <select
                                            id="location"
                                            name="location"
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => {
                                                console.log(e.target.value);
                                                setCustomer(parseInt(e.target.value));
                                            }}
                                            value={customer}
                                        >
                                            <option value="0">Angels In Your Home</option>
                                            <option value="1">Hilton East</option>
                                            <option value="2">Angels In Your Home + Hilton East</option>
                                        </select>

                                    </div>
                                    {/* <div className="mt-5">
                                        <Toggle label={'Show Debug'} enabled={showDebug} setEnabled={setShowDebug} />
                                    </div> */}
                                    <button
                                        type="button"
                                        id="btn"
                                        onClick={() => download()}
                                        className="mt-5 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Process Payroll
                                        <svg
                                            className="-mr-0.5 h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                    <div className={(chartsReady ? "" : "hidden") + "  block"}>
                                        <button type='button' onClick={() => {
                                            downloadURI((process.env.NODE_ENV === "development" ? "http://localhost:4567" : "https://process-payroll-api.clearerc.com") + "/download?filename=" + filename, "payroll.csv");

                                        }}
                                            className="mt-5 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Download File
                                        </button>
                                    </div>

                                    <div
                                        id="status"
                                        className={(showDebug ? "" : "hidden") + " text-xs h-16 border-gray-100 shadow border rounded mt-5 overflow-auto"}
                                    />
                                    <div
                                        id="status2"
                                        className={(showDebug ? "" : "hidden") + " text-xs h-96 border-gray-100 shadow border rounded mt-5 overflow-auto"}
                                    >
                                        <ul>
                                            {statusMessages.map((msg, i) => (
                                                <li key={i}>
                                                    *&nbsp;
                                                    {msg}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-span-2  rounded border-gray-300">
                                    <div className=" w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
                                        <div className="flex justify-between pb-4 mb-4 border-b border-gray-200 dark:border-gray-700">
                                            <div className="gap-5">
                                                <div className="flex flex-row items-center pb-5">
                                                    <div className="w-12 h-12 rounded-lg bg-gray-100 dark:bg-gray-700 flex items-center justify-center mr-3">
                                                        <svg
                                                            className="w-6 h-6 text-gray-500 dark:text-gray-400"
                                                            aria-hidden="true"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="currentColor"
                                                            viewBox="0 0 20 19"
                                                        >
                                                            <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z" />
                                                            <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z" />
                                                        </svg>
                                                    </div>
                                                    <div className="flex flex-row gap-5">
                                                        <h5
                                                            className=" leading-none text-2xl font-bold text-gray-900 dark:text-white pb-1"
                                                            id="avg"
                                                        >
                                                            0
                                                        </h5>
                                                        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                                            Avgerage full time employees
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row items-center">
                                                    <div className="w-12 h-12 rounded-lg bg-gray-100 dark:bg-gray-700 flex items-center justify-center mr-3">
                                                        <svg
                                                            className="w-6 h-6 text-gray-500 dark:text-gray-400"
                                                            aria-hidden="true"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="currentColor"
                                                            viewBox="0 0 20 19"
                                                        >
                                                            <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z" />
                                                            <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z" />
                                                        </svg>
                                                    </div>
                                                    <div className="flex flex-row gap-5">
                                                        <h5
                                                            className=" leading-none text-2xl font-bold text-gray-900 dark:text-white pb-1"
                                                            id="avg"
                                                        >
                                                            {files.length}
                                                        </h5>
                                                        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                                            Files Downloaded
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="column-chart" />
                                        <div id="column-chart2" />
                                        {/* <MonthlyChart totals={totalsData} partTimeTotals={partTimeData} /> */}
                                        {/* <QuarterlyChart totals={totalsData} partTimeTotals={partTimeData} /> */}

                                        <div className='h-full w-full'>
                                            {/* <pre>
                                                {JSON.stringify(daysCovered, null, 2)}
                                            </pre> */}
                                            {daysCoveredCount.map((d, i) => {
                                                return <div key={i} className={"inline-block w-8 mr-1 -mb-1 p-0 m-0 " + (d > 0 ? "bg-green-500 text-white" : "bg-red-500 text-white") + " text-xs text-center"}>{(dateFromDay(2019, i + 1).getMonth() + 1) + "/" + dateFromDay(2019, i + 1).getDate()}</div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>

    )
}
