import React, { Suspense } from 'react';
import logo from './logo.svg';
import { BrowserRouter, Routes, Route, RouterProvider, createBrowserRouter } from "react-router-dom";
import './App.css';
import Signin from './accounts/signin';
import { basename } from 'path';
import SignOut from './accounts/signout';
import "./index.css"
import Dashboard from './dashboard/dashboard';
import Single from './dashboard/single';
import Env from './dashboard/env';
import Compare from './dashboard/compare';
import Create from './dashboard/create';
import CreateChart from './dashboard/createChart';
import { Gantt } from './dashboard/gantt';
import GanttCreator from './dashboard/gantt-creator';

const App = () => {


  const routes = [
    {
      path: "/",
      element: <Signin />,
    },
    {
      path: "/signout",
      element: <SignOut />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/gantt",
      element: <Gantt />,
    },
    {
      path: "/gantt-creator",
      element: <GanttCreator />,
    },
    {
      path: "/compare",
      element: <Compare />,
    },
    {
      path: "/create",
      element: <Create />,
    },
    {
      path: "/create-chart",
      element: <CreateChart />,
    },
    {
      path: "/single",
      element: <Single />,
    },
    {
      path: "/env",
      element: <Env />,
    },
    {
      path: "/",
      element: <Signin />,
    }
  ];

  return <Suspense>
    <div className="">
  <RouterProvider router={createBrowserRouter(routes)} />
  </div>
</Suspense>
};
export default App;
