import ApexCharts from "apexcharts";

export default function generateChart({
  data,
  title,
  div,
  color = "#1A56DB",
}: {
  data: any;
  title: string;
  div: string;
  color: string;
}) {
  const options2 = {
    colors: [color],
    series: [
      {
        name: title,
        color: color,
        type: "column",
        data
      },
      // {
      //   // name: title,
      //   color: color,
      //   type: "line",
      //   data
      // }
    ],
    chart: {
      type: "bar",
      height: "320px",
      fontFamily: "Inter, sans-serif",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        borderRadiusApplication: "end",
        borderRadius: 8,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontFamily: "Inter, sans-serif",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    stroke: {
      width: [2, 2, 5],
          curve: 'smooth'
    },
    grid: {
      show: true,
      strokeDashArray: 4,
      padding: {
        left: 2,
        right: 2,
        top: -14,
      },
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
    },
    xaxis: {
      floating: false,
      labels: {
        show: true,
        style: {
          fontFamily: "Inter, sans-serif",
          cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      show: true,
    },
    fill: {
      opacity: [0.5, 0.25, 0.1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.0,
        stops: [0, 100]
      }
    },
  };

  if (document.getElementById(div) && typeof ApexCharts !== "undefined") {
    const chart2 = new ApexCharts(document.getElementById(div), options2);
    chart2.render();
  } else {
    console.log("Column Chart not found");
  }
}
