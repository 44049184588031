import React from 'react'

export default function Menu() {
  return (
    <div>
        
                    <nav className="border-b border-indigo-300 border-opacity-25 bg-indigo-600 lg:border-none">
                        <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                            <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                                <div className="flex items-center px-2 lg:px-0">
                                    <div className="flex-shrink-0">
                                        <img className="block h-8" src="logo.png" alt="Your Company" />
                                    </div>
                                    <div className="hidden lg:ml-10 lg:block">
                                        <div className="flex space-x-4">

                                            {/* Current: "bg-indigo-700 text-white", Default: "text-white hover:bg-indigo-500 hover:bg-opacity-75" */}
                                            <a
                                                href="/dashboard"
                                                className="bg-indigo-700 text-white rounded-md py-2 px-3 text-sm font-medium"
                                                aria-current="page"
                                            >
                                                Process Payroll
                                            </a>
                                            <a
                                                href="/compare"
                                                className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                            >
                                                Compare 2019 with 2021
                                            </a>
                                            <a
                                                href="/create"
                                                className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                            >
                                                Create Quarterly Chart
                                            </a>
                                            <a
                                                href="/gantt-creator"
                                                className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                            >
                                                Create Gantt Chart
                                            </a>
                                            <a
                                                href="/create-chart"
                                                className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                            >
                                                Create Chart
                                            </a>
                                            <a
                                                href="/single"
                                                className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                            >
                                                Process File
                                            </a>
                                            <a
                                                href="/"
                                                className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                                            >
                                                Logout
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex lg:hidden">
                                    {/* Mobile menu button */}
                                    <button
                                        type="button"
                                        className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                        aria-controls="mobile-menu"
                                        aria-expanded="false"
                                    >
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {/* Menu open: "hidden", Menu closed: "block" */}
                                        <svg
                                            className="block h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                            />
                                        </svg>
                                        {/* Menu open: "block", Menu closed: "hidden" */}
                                        <svg
                                            className="hidden h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Mobile menu, show/hide based on menu state. */}
                        <div className="lg:hidden" id="mobile-menu">
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {/* Current: "bg-indigo-700 text-white", Default: "text-white hover:bg-indigo-500 hover:bg-opacity-75" */}
                                <a
                                    href="/dashboard"
                                    className="bg-indigo-700 text-white block rounded-md py-2 px-3 text-base font-medium"
                                    aria-current="page"
                                >
                                    Process Payroll
                                </a>
                                <a
                                    href="/compare"
                                    className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                                >
                                    Compare 2019 with 2021
                                </a>
                                <a
                                    href="/create"
                                    className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                                >
                                    Create Quarterly Chart
                                </a>
                                <a
                                    href="/create-chart"
                                    className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                                >
                                    Create Chart
                                </a>
                                <a
                                    href="/gantt-creator"
                                    className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                                >
                                    Create Gantt Chart
                                </a>
                                <a
                                    href="/single"
                                    className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                                >
                                    Process File
                                </a>
                                <a
                                    href="/"
                                    className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                                >
                                    Logout
                                </a>
                            </div>
                        </div>
                    </nav>
                    
    </div>
  )
}
