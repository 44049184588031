import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import Menu from "../layout/menu";
import createChart from "./createChart";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRangePicker } from 'react-date-range';
import ReactApexChart from "react-apexcharts";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { HexColorPicker } from "react-colorful";

export default function GanntCreator() {
  const [data, setData] = useState<any[]>([]);
  const [val1, setVal1] = useState<string[]>(['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022']);
  const [val2, setVal2] = useState("#ff00ff");
  const [val3, setVal3] = useState("65");
  const [startDate, setStartDate] = useState(new Date('2020-01-01'));
  const [endDate, setEndDate] = useState(new Date('2021-01-01'));

  const chart = {series: [
    {
      name: 'EO235',
      data: [
        {
          x: ['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
          y: [
            new Date('2020-04-15').getTime(),
            new Date('2021-07-01').getTime()
          ],
          fillColor: '#008FFB'
        },
        {
          x: ['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
          y: [
            new Date('2021-07-12').getTime(),
            new Date('2022-03-01').getTime()
          ],
          fillColor: '#008FFB'
        },
        {
          x: ['Masking','Baltimore City', 'Apr 3, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
          y: [
            new Date('2020-04-03').getTime(),
            new Date('2021-07-01').getTime()
          ],
          fillColor: '#00E396'
        },
        {
          x: ['Masking','Baltimore City', 'Apr 3, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
          y: [
            new Date('2021-07-12').getTime(),
            new Date('2022-03-01').getTime()
          ],
          fillColor: '#00E396'
        },
        
        {
          x: ['50% Occupancy', 'Maryland State', 'Jun 10, 2020 - May 15, 2021'],
          y: [
            new Date('2020-06-10').getTime(),
            new Date('2021-05-15').getTime()
          ],
          fillColor: '#775DD0'
        },
        {
          x: ['25% Occupancy','Baltimore City', 'Aug 7, 2020 - May 15, 2021'],
          y: [
            new Date('2020-08-07').getTime(),
            new Date('2021-05-15').getTime()
          ],
          fillColor: '#FEB019'
        },
      ]
    },

  ],
  options: {
      series: [
          {
            name: 'EO235',
            data: [
              {
                x: ['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                y: [
                  new Date('2020-04-15').getTime(),
                  new Date('2021-07-01').getTime()
                ],
                fillColor: '#008FFB'
              },
              {
                x: ['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                y: [
                  new Date('2021-07-12').getTime(),
                  new Date('2022-03-01').getTime()
                ],
                fillColor: '#008FFB'
              },
              {
                x: ['Masking','Baltimore City', 'Apr 3, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                y: [
                  new Date('2020-04-03').getTime(),
                  new Date('2021-07-01').getTime()
                ],
                fillColor: '#00E396'
              },
              {
                x: ['Masking','Baltimore City', 'Apr 3, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                y: [
                  new Date('2021-07-12').getTime(),
                  new Date('2022-03-01').getTime()
                ],
                fillColor: '#00E396'
              },
              
              {
                x: ['50% Occupancy', 'Maryland State', 'Jun 10, 2020 - May 15, 2021'],
                y: [
                  new Date('2020-06-10').getTime(),
                  new Date('2021-05-15').getTime()
                ],
                fillColor: '#775DD0'
              },
              {
                x: ['25% Occupancy','Baltimore City', 'Aug 7, 2020 - May 15, 2021'],
                y: [
                  new Date('2020-08-07').getTime(),
                  new Date('2021-05-15').getTime()
                ],
                fillColor: '#FEB019'
              },
            //   {
            //     x: 'Validation',
            //     y: [
            //       new Date('2019-03-11').getTime(),
            //       new Date('2019-03-16').getTime()
            //     ]
            //   },
            //   {
            //     x: 'Design',
            //     y: [
            //       new Date('2019-03-01').getTime(),
            //       new Date('2019-03-03').getTime()
            //     ],
            //   }
            ]
          },
        //   {
        //     name: 'Masking - Baltimore City',
        //     data: [
        //         {
        //             x: 'Masking - Baltimore City',
        //             y: [
        //               new Date('2019-03-01').getTime(),
        //               new Date('2019-03-07').getTime()
        //             ]
        //           },
        //       {
        //         x: 'Design',
        //         y: [
        //           new Date('2019-03-02').getTime(),
        //           new Date('2019-03-05').getTime()
        //         ]
        //       },
        //       {
        //         x: 'Test',
        //         y: [
        //           new Date('2019-03-06').getTime(),
        //           new Date('2019-03-16').getTime()
        //         ],
                
        //       },
        //       {
        //         x: 'Code',
        //         y: [
        //           new Date('2019-03-03').getTime(),
        //           new Date('2019-03-07').getTime()
        //         ]
        //       },
        //       {
        //         x: 'Deployment',
        //         y: [
        //           new Date('2019-03-20').getTime(),
        //           new Date('2019-03-22').getTime()
        //         ]
        //       },
        //       {
        //         x: 'Design',
        //         y: [
        //           new Date('2019-03-10').getTime(),
        //           new Date('2019-03-16').getTime()
        //         ]
        //       }
        //     ]
        //   },
        //   {
        //     name: '50% Occupancy - Marland State',
        //     data: [
        //       {
        //         x: 'Code',
        //         y: [
        //           new Date('2019-03-10').getTime(),
        //           new Date('2019-03-17').getTime()
        //         ]
        //       },
        //       {
        //         x: 'Validation',
        //         y: [
        //           new Date('2019-03-05').getTime(),
        //           new Date('2019-03-09').getTime()
        //         ],
                
        //       },
        //     ]
        //   }
        ],
    chart: {
      height: 450,
      type: 'rangeBar',
      offsetX: 60
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        borderRadius: 8,
      }
    },
    xaxis: {
      labels: {
          show: false,
        formatter: function (value:any) {
          return new Date(value).toLocaleDateString('en-US');;
        },

      },
      tickAmount: 12,
      
    },
    stroke: {
      width: 0
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: "vertical",
        shadeIntensity: 0.3,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [0, 100],
        colorStops: []
      }
    },
    annotations: {
      xaxis: [
        {
          x: new Date('1 April 2020').getTime(),
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#000',
            },
            text: 'Q2 2020'
          }
        },
        {
          x: new Date('1 July 2020').getTime(),
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#000',
            },
            text: 'Q3 2020'
          }
        },
        {
          x: new Date('1 October 2020').getTime(),
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#000',
            },
            text: 'Q4 2020'
          }
        },
        {
          x: new Date('1 January 2021').getTime(),
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#000',
            },
            text: 'Q1 2021'
          }
        },
        {
          x: new Date('1 April 2021').getTime(),
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#000',
            },
            text: 'Q2 2021'
          }
        },
        {
          x: new Date('1 July 2021').getTime(),
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#000',
            },
            text: 'Q3 2021'
          }
        },
        {
          x: new Date('1 October 2021').getTime(),
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#000',
            },
            text: 'Q4 2021'
          }
        },
        {
          x: new Date('1 January 2022').getTime(),
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#000',
            },
            text: 'Q1 2022'
          }
        },
      ]
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    yaxis: {
      labels: {
      style: {
          fontSize: '11px',
      }
  }
    },
    grid: {
      show: true,
      borderColor: '#90A4AE',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
          lines: {
              show: false
          }
      },   
      yaxis: {
          lines: {
              show: false
          }
      },  
      row: {
          colors: undefined,
          opacity: 0.5
      },  
      column: {
          colors: undefined,
          opacity: 0.5
      },  
      padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
      },  
  }
  },


};

  const [open, setOpen] = useState(false);
  function addRow() {
    const newData = [...data];
    newData.push({ x: val1, y: [startDate.getTime(), endDate.getTime()] , fillColor: val2});
    setData(newData);
  }
  function removeRow() {
    const newData = [...data];
    newData.pop();
    setData(newData);
  }

  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
          <Menu />
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl tracking-widest text-white">
                Create Gantt Chart
              </h1>
            </div>
          </header>
        </div>
        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 min-h-96 ">
              <div className="grid grid-cols-3 gap-5">
                <div className="mt-5 col-span-3">
                  
                  {/* Button to add a new row */}
                  <div className="mt-5 grid grid-cols-4 gap-5">
                    <div>
                      <label
                        htmlFor="title1"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Row Titles
                      </label>
                      <textarea
                        value={val1.join("\n")}
                        onChange={(e) => setVal1(e.target.value.split(/\n/))}
                        rows={5}
                        name="title1"
                        id="title1"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="title1"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Start Date
                      </label>
                      <DatePicker selected={startDate} onChange={(date) => setStartDate(date??new Date())} />

                    </div>
                    <div>
                      <label
                        htmlFor="title1"
                        className="block text-sm font-medium text-gray-700"
                      >
                        End Date
                      </label>
                      <DatePicker selected={endDate} onChange={(date) => setEndDate(date??new Date())} />

                    </div>
                    <div>
                      <label
                        htmlFor="q42"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Color
                      </label>
                      <HexColorPicker color={val2} onChange={setVal2} />
                    </div>
                    <div className="mt-auto">
                      <button
                        onClick={addRow}
                        id="btnCompare"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        Add Row
                      </button>
                      <button
                        onClick={removeRow}
                        id="btnCompare"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                      >
                        Remove Row
                      </button>
                    </div>
                  </div>
                  {/* Display rows as table */}
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mt-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                  >
                                    Title
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Value
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {data.map((person, idx) => (
                                  <tr key={idx}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {person.x}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {person.y}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 mt-5">
                    
                  </div>

                  <button
                    // onClick={createChart}
                    id="btnCompare"
                    className="mt-5 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  >
                    Create Chart
                  </button>
                  <ReactApexChart options={(chart as any).options} series={[{name: "EO", data: data}]} type="rangeBar" height={450} />
                  <div className="p-2">
                    <div id="column-chart1" />

                    <div id="column-chart3" />
                    <div id="column-chart4" />
                    <div id="column-chart5" />
                    <div id="column-chart6" />
                    <div id="column-chart7" />
                    <div id="column-chart2" />

                    <div className="h-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
