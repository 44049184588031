import React from 'react'

export default function Env() {
  return (
    <div className="bg-white">
        <pre>
            {JSON.stringify(process.env, null, 2)}
        </pre>
    </div>
  )
}
