

export function readRemoteCSVFile(url: string | URL) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader ('Authorization', 'Bearer ' + localStorage.getItem("token"));
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            var csvData = xhr.responseText;
            console.log(csvData)

            // process csvData by newLine
        }
    };
    xhr.send();
}