import React from 'react'

export default function Signin() {
  const [error, setError] = React.useState("")
  const signInURL = "https://process-payroll-api.clearerc.com/signin/"
  function login() {
    setError("")
    console.log("login")
    // Get the email and password from the form
    const email = (document.getElementById("email") as HTMLInputElement).value
    const password = (document.getElementById("password") as HTMLInputElement).value
    // Send the email and password to the server
    const xhr = new XMLHttpRequest()
    xhr.open("POST", signInURL)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.send(JSON.stringify({ email, password }))
    // If the server says the login was successful, redirect to the home page
    let status = false;
    xhr.onload = () => {
      if (xhr.status === 200) {
        // Save the token as a cookie
        document.cookie = `token=${xhr.response}`
        localStorage.setItem("token", JSON.parse(xhr.response).token)
        console.log("Login successful")
        console.log(xhr.response)
        window.location.href = "/dashboard"
        status = true;
        return;
      } else {
        console.log("Login failed")
        setError("Invalid Username or Password")
      }
        
      
    }
    

  }
  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src="logo.png" alt="Your Company" />
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action={signInURL} method="POST">
            <div>
              {error && (<p className="text-red-500 text-xl italic mb-5">{error}</p>)}
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"

                  className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"

                  className="px-3 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                onClick={() => login()}
                className="mt-10 flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>

  )
}
