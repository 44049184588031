import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { ClickToComponent } from 'click-to-react-component';

const root = createRoot(document.getElementById("root")!);

root.render(
    <>
         <ClickToComponent />
         <App />
       </>
  
);