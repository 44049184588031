import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";
import Menu from "../layout/menu";
import generateQuarterlyChart from "./quarterly-chart";
import generateQuarterlyChartOt from "./quarterly-chart-ot";

export default function Compare() {
  const [data, setData] = React.useState<any[]>([]);

  function compare() {
    console.log("Compare");
    const btn = document.getElementById("btnCompare");
    if (btn) {
      btn.innerHTML = "Processing...";
    }
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open(
      "GET",
      (process.env.NODE_ENV === "development"
        ? "http://localhost:4567"
        : "https://process-payroll-api.clearerc.com") + "/get2019",
      true
    );
    xmlHttp.setRequestHeader("Cache-Control", "no-cache");
    xmlHttp.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    xmlHttp.onreadystatechange = () => {
      // In local files, status is 0 upon success in Mozilla Firefox
      if (xmlHttp.readyState === XMLHttpRequest.DONE) {
        const status = xmlHttp.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
          const response = JSON.parse(xmlHttp.responseText);
          setData(response);
          // Get total overtime per company per quarter
          const overtime: any = {};
          const overtime2021: any = {};
          const employeeCount: any = {};
          const employeeCount2021: any = {};
          let ftEmployeeCount: any = {};
          
          console.log({ response2019: response[2019] });
          for (const line of response[2019]) {
            if (!overtime[line.company]) {
              overtime[line.company] = {};
            }
            if (!employeeCount[line.company]) {
              employeeCount[line.company] = {};
            }
            if (!employeeCount[line.company][line.quarter]) {
              employeeCount[line.company][line.quarter] = 0;
            }
            if (line.hours > 0) {
              employeeCount[line.company][line.quarter]++;
            }
            if (overtime[line.company][line.quarter]) {
              overtime[line.company][line.quarter] += line.ot;
            } else {
              overtime[line.company][line.quarter] = line.ot;
            }
            if (line.hours > 390) {
              if (!ftEmployeeCount[line.company]) {
                ftEmployeeCount[line.company] = {};
              }
              if (!ftEmployeeCount[line.company][line.quarter]) {
                ftEmployeeCount[line.company][line.quarter] = 0;
              }
              ftEmployeeCount[line.company][line.quarter]++;
            }
          }
          console.log({ overtime, employeeCount });

          // Get total hours per company per quarter
          const hours: any = {};
          let FTovertime2019: any = {};
          
          for (const line of response[2019]) {
            if (!hours[line.company]) {
              hours[line.company] = {};
              FTovertime2019[line.company] = {};
            }
            if (hours[line.company][line.quarter]) {
              hours[line.company][line.quarter] += line.hours;
            } else {
              hours[line.company][line.quarter] = line.hours;
            }
            if (line.hours > 390) {
              if (FTovertime2019[line.company][line.quarter]) {
                FTovertime2019[line.company][line.quarter] += line.ot;
              } else {
                FTovertime2019[line.company][line.quarter] = line.ot;
              }
            }
          }

          for (const line of response[2021]) {
            if (!overtime2021[line.company]) {
              overtime2021[line.company] = {};
            }
            if (!employeeCount2021[line.company]) {
              employeeCount2021[line.company] = {};
            }
            if (!employeeCount2021[line.company][line.quarter]) {
              employeeCount2021[line.company][line.quarter] = 0;
            }
            if (line.hours > 0) {
              employeeCount2021[line.company][line.quarter]++;
            }
            if (overtime2021[line.company][line.quarter]) {
              overtime2021[line.company][line.quarter] += line.ot;
            } else {
              overtime2021[line.company][line.quarter] = line.ot;
            }
          }
          console.log({
            overtime,
            overtime2021,
            employeeCount,
            employeeCount2021,
          });
          
          let FTovertime2021: any = {};
          
          // Get total hours per company per quarter
          const hours2021: any = {};
          let ftEmployeeCount2021: any = {};
          for (const line of response[2021]) {
            console.log(line);
            if (!hours2021[line.company]) {
              hours2021[line.company] = {};              
              FTovertime2021[line.company] = {};
            }
            if (hours2021[line.company][line.quarter]) {
              hours2021[line.company][line.quarter] += line.hours;
            } else {
              hours2021[line.company][line.quarter] = line.hours;
            }
            if (line.hours > 390) {
              if (!ftEmployeeCount2021[line.company]) {
                ftEmployeeCount2021[line.company] = {};
              }
              if (!ftEmployeeCount2021[line.company][line.quarter]) {
                ftEmployeeCount2021[line.company][line.quarter] = 0;
              }
              ftEmployeeCount2021[line.company][line.quarter]++;

              if (FTovertime2021[line.company][line.quarter]) {
                FTovertime2021[line.company][line.quarter] += line.ot;
              } else {
                FTovertime2021[line.company][line.quarter] = line.ot;
              }
            }
          }

          // const employeeCount:any = {};
          let Thours: any = {};
          let Tovertime: any = {};
          let Thours2021: any = {};
          let Tovertime2021: any = {};
          let avgFTTovertime2019: any = {};
          let avgFTTovertime2021: any = {};
          for (const company in hours) {
            // Divide by employee count
            for (const quarter in hours[company]) {
              if (!Thours[company]) {
                Thours[company] = {};
              }
              if (!Tovertime[company]) {
                Tovertime[company] = {};
              }
              if (!Thours2021[company]) {
                Thours2021[company] = {};
              }
              if (!Tovertime2021[company]) {
                Tovertime2021[company] = {};
              }
              Thours[company][quarter] =
                ((hours[company][quarter] / employeeCount[company][quarter]) *
                  4) /
                52;
              Tovertime[company][quarter] =
                ((overtime[company][quarter] /
                  employeeCount[company][quarter]) *
                  4) /
                52;
              Thours2021[company][quarter] =
                ((hours2021[company][quarter] /
                  employeeCount2021[company][quarter]) *
                  4) /
                52;
              Tovertime2021[company][quarter] =
                ((overtime2021[company][quarter] /
                  employeeCount2021[company][quarter]) *
                  4) /
                52;


              if (!avgFTTovertime2019[company]) {
                avgFTTovertime2019[company] = {};
              }
              if (!avgFTTovertime2021[company]) {
                avgFTTovertime2021[company] = {};
              }

              avgFTTovertime2019[company][quarter] =
                ((FTovertime2019[company][quarter] /
                ftEmployeeCount[company][quarter]) *
                  4) /
                52;
              avgFTTovertime2021[company][quarter] =
                ((FTovertime2021[company][quarter] /
                  ftEmployeeCount2021[company][quarter]) *
                  4) /
                52;
            }
          }
          console.log(hours);
          let options1 = {
            totals: hours["WWM"],
            partTimeTotals: hours2021["WWM"],
            title1: "Average Hours 2019",
            title2: "Average Hours 2021",
            div: "column-chart1",
            color1: "#1A56DB",
            color2: "#ff0000",
          };
          generateQuarterlyChartOt(options1);
          
          generateQuarterlyChartOt({
            totals: overtime["WWM"],
            partTimeTotals: overtime2021["WWM"],
            title1: "Total Overtime Hours 2019",
            title2: "Total Overtime Hours 2021",
            div: "column-chart3",

            color1: "#1A56DB",
            color2: "#FDBA8C",
          });
          generateQuarterlyChartOt({
            totals: Tovertime["WWM"],
            partTimeTotals: Tovertime2021["WWM"],
            title1: "Average Overtime Hours 2019",
            title2: "Average Overtime Hours 2021",
            div: "column-chart4",

            color1: "#000000",
            color2: "#0bd8bd",
          });
          if (btn) {
            btn.innerHTML = "Compare";
          }
          generateQuarterlyChartOt({
            totals: FTovertime2019["WWM"],
            partTimeTotals: FTovertime2021["WWM"],
            title1: "Fulltime (130 x 3) Total Overtime Hours 2019",
            title2: "Fulltime (130 x 3) Total Overtime Hours 2021",
            div: "column-chart5",

            color1: "#000000",
            color2: "#0bd8bd",
          });
          if (btn) {
            btn.innerHTML = "Compare";
          }
          generateQuarterlyChartOt({
            totals: avgFTTovertime2019["WWM"],
            partTimeTotals: avgFTTovertime2021["WWM"],
            title1: "Fulltime (130 x 3) Average Overtime Hours 2019",
            title2: "Fulltime (130 x 3) Average Overtime Hours 2021",
            div: "column-chart6",

            color1: "#000000",
            color2: "#0bd8bd",
          });
          generateQuarterlyChartOt({
            totals: ftEmployeeCount["WWM"],
            partTimeTotals: ftEmployeeCount2021["WWM"],
            title1: "Fulltime Employees 2019",
            title2: "Fulltime Employees 2021",
            div: "column-chart7",

            color1: "#000000",
            color2: "#0bd8bd",
          });
          generateQuarterlyChartOt({
            totals: employeeCount["WWM"],
            partTimeTotals: employeeCount2021["WWM"],
            title1: "Employees 2019",
            title2: "Employees 2021",
            div: "column-chart2",

            color1: "#10cb2c",
            color2: "#360d50",
          });
          if (btn) {
            btn.innerHTML = "Compare";
          }
          // generateQuarterlyChartOt({ totals:overtime["WWM"], partTimeTotals:overtime2021["WWM"], title1: "Average Overtime Hours 2019", title2: "Average Overtime Hours 2021", div: "column-chart5" });
        } else {
          // Oh no! There has been an error with the request!
        }
      }
    };

    xmlHttp.send(null);
  }

  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
          <Menu />
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl tracking-widest text-white">
                Compare 2019 with 2021
              </h1>
            </div>
          </header>
        </div>
        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 min-h-96 ">
              <div className="grid grid-cols-3 gap-5">
                <div className="mt-5 col-span-3">
                  <button
                    onClick={compare}
                    id="btnCompare"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  >
                    Compare
                  </button>

                  <div className="p-2">
                    <div id="column-chart1" />
                    
                    <div id="column-chart3" />
                    <div id="column-chart4" />
                    <div id="column-chart5" />
                    <div id="column-chart6" />
                    <div id="column-chart7" />
                    <div id="column-chart2" />
                    <div className="grid grid-cols-2">
                      <div>
                        <h1>2019</h1>
                        <table>
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Quarter</th>
                              <th>Name</th>
                              <th>Hours</th>
                              <th>OT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data["2019"]?.map((item: any) => (
                              <tr key={item.id}>
                                <td>{item.company}</td>
                                <td>{item.quarter}</td>
                                <td>{item.name}</td>
                                <td>{item.hours}</td>
                                <td>{item.ot}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <h1>2021</h1>
                        <table>
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Quarter</th>
                              <th>Name</th>
                              <th>Hours</th>
                              <th>OT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data["2021"]?.map((item: any) => (
                              <tr key={item.id}>
                                <td>{item.company}</td>
                                <td>{item.quarter}</td>
                                <td>{item.name}</td>
                                <td>{item.hours}</td>
                                <td>{item.ot}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="h-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
