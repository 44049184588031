import ApexCharts from 'apexcharts'

export default function generateMonthlyChart({totals, partTimeTotals}: {totals: any, partTimeTotals: any}) {
  const options = {
    colors: ["#1A56DB", "#FDBA8C"],
    series: [
        {
            name: "Full Time Employees",
            color: "#1A56DB",
            data: [
                { x: "January", y: totals["January"] },
                { x: "February", y: totals["February"] },
                { x: "March", y: totals["March"] },
                { x: "April", y: totals["April"] },
                { x: "May", y: totals["May"] },
                { x: "June", y: totals["June"] },
                { x: "July", y: totals["July"] },
                { x: "August", y: totals["August"] },
                { x: "September", y: totals["September"] },
                { x: "October", y: totals["October"] },
                { x: "November", y: totals["November"] },
                { x: "December", y: totals["December"] },


            ],
        },
        {
            name: "Part Time Employees",
            color: "#ff0000",
            data: [
                { x: "January", y: partTimeTotals["January"] },
                { x: "February", y: partTimeTotals["February"] },
                { x: "March", y: partTimeTotals["March"] },
                { x: "April", y: partTimeTotals["April"] },
                { x: "May", y: partTimeTotals["May"] },
                { x: "June", y: partTimeTotals["June"] },
                { x: "July", y: partTimeTotals["July"] },
                { x: "August", y: partTimeTotals["August"] },
                { x: "September", y: partTimeTotals["September"] },
                { x: "October", y: partTimeTotals["October"] },
                { x: "November", y: partTimeTotals["November"] },
                { x: "December", y: partTimeTotals["December"] },


            ],
        },

    ],
    chart: {
        type: "bar",
        height: "320px",
        fontFamily: "Inter, sans-serif",
        toolbar: {
            show: false,
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: "70%",
            borderRadiusApplication: "end",
            borderRadius: 8,
        },
    },
    tooltip: {
        shared: true,
        intersect: false,
        style: {
            fontFamily: "Inter, sans-serif",
        },
    },
    states: {
        hover: {
            filter: {
                type: "darken",
                value: 1,
            },
        },
    },
    stroke: {
        show: true,
        width: 0,
        colors: ["transparent"],
    },
    grid: {
        show: true,
        strokeDashArray: 4,
        padding: {
            left: 2,
            right: 2,
            top: -14
        },
    },
    dataLabels: {
        enabled: false,
    },
    legend: {
        show: true,
    },
    xaxis: {
        floating: false,
        labels: {
            show: true,
            style: {
                fontFamily: "Inter, sans-serif",
                cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400'
            }
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
    yaxis: {
        show: true,
    },
    fill: {
        opacity: 1,
    },
}


if (document.getElementById("column-chart") && typeof ApexCharts !== 'undefined') {
  const chart = new ApexCharts(document.getElementById("column-chart"), options);
  chart.render();
} else {
  console.log("Column Chart not found");
}

}
