import ApexCharts from 'apexcharts'

export default function generateQuarterlyChart({totals, partTimeTotals, title1 = "Full Time Employees", title2 = "Part Time Employees"}: {totals: any, partTimeTotals: any, title1: string, title2: string}) {
    const options2 = {
        colors: ["#1A56DB", "#FDBA8C"],
        series: [
            {
                name: title1,
                color: "#1A56DB",
                data: [
                    { x: "Q1", y: Math.round((totals["January"]+totals["February"]+totals["March"])/3.0) },
                    { x: "Q2", y: Math.round((totals["April"]+totals["May"]+totals["June"])/3.0) },
                    { x: "Q3", y: Math.round((totals["July"]+totals["August"]+totals["September"])/3.0) },
                    { x: "Q4", y: Math.round((totals["October"]+totals["November"]+totals["December"])/3.0) },
                    

                ],
            },
            {
                name: title2,
                color: "#ff0000",
                data: [
                    { x: "Q1", y: Math.round((partTimeTotals["January"]+partTimeTotals["February"]+partTimeTotals["March"])/3.0) },
                    { x: "Q2", y: Math.round((partTimeTotals["April"]+partTimeTotals["May"]+partTimeTotals["June"])/3.0) },
                    { x: "Q3", y: Math.round((partTimeTotals["July"]+partTimeTotals["August"]+partTimeTotals["September"])/3.0) },
                    { x: "Q4", y: Math.round((partTimeTotals["October"]+partTimeTotals["November"]+partTimeTotals["December"])/3.0) },
                    
                ],
            },

        ],
        chart: {
            type: "bar",
            height: "320px",
            fontFamily: "Inter, sans-serif",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "70%",
                borderRadiusApplication: "end",
                borderRadius: 8,
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
            style: {
                fontFamily: "Inter, sans-serif",
            },
        },
        states: {
            hover: {
                filter: {
                    type: "darken",
                    value: 1,
                },
            },
        },
        stroke: {
            show: true,
            width: 0,
            colors: ["transparent"],
        },
        grid: {
            show: true,
            strokeDashArray: 4,
            padding: {
                left: 2,
                right: 2,
                top: -14
            },
        },
        dataLabels: {
            enabled: true,
        },
        legend: {
            show: true,
        },
        xaxis: {
            floating: false,
            labels: {
                show: true,
                style: {
                    fontFamily: "Inter, sans-serif",
                    cssClass: 'text-xs font-normal fill-gray-500 dark:fill-gray-400'
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            show: true,
        },
        fill: {
            opacity: 1,
        },
    }


    if (document.getElementById("column-chart") && typeof ApexCharts !== 'undefined') {
        const chart2 = new ApexCharts(document.getElementById("column-chart2"), options2);
        chart2.render();
    } else {
        console.log("Column Chart not found");
    }


}
