interface Totals {
    [name: string]: number
}

export function getTotalsFromCSV({ csvData, customer }: { csvData: string[][], customer: number }) {
    // Add up all of the people per column 1 that have a 1 in 3rd column
    if (customer !== 2) {
        var total = 0;
        var totals: Totals = {
            "January": 0,
            "February": 0,
            "March": 0,
            "April": 0,
            "May": 0,
            "June": 0,
            "July": 0,
            "August": 0,
            "September": 0,
            "October": 0,
            "November": 0,
            "December": 0
        };
        var partTimeTotals: Totals = {
            "January": 0,
            "February": 0,
            "March": 0,
            "April": 0,
            "May": 0,
            "June": 0,
            "July": 0,
            "August": 0,
            "September": 0,
            "October": 0,
            "November": 0,
            "December": 0
        };
    
        for (var i = 0; i < csvData.length; i++) {
            var currentline = csvData[i];
            if (currentline[3] == "1") { // Full Time Employee
                // total += parseInt(currentline[1]);
                if (!totals[currentline[0]]) {
                    totals[currentline[0]] = 0;
                }
                totals[currentline[0]] += parseInt(currentline[3]);
                // console.log({ month: currentline[0], count: currentline })
                total += parseInt(currentline[3]);
            } else {
                // total += parseInt(currentline[1]);
                if (!partTimeTotals[currentline[0]]) {
                    partTimeTotals[currentline[0]] = 0;
                }
                partTimeTotals[currentline[0]] += 1;
                // partTime += parseInt(currentline[3]);
                // console.log({ month: currentline[0], count: currentline[2], partTime: currentline[3] })
            }
    
    
        }
        // console.log({ partTimeTotals })
    
        const status = document.getElementById("status");
        if (status) {
            status.innerHTML = JSON.stringify(totals) + "\n\n\n" + JSON.stringify(partTimeTotals);
        }
        // document.getElementById("status").innerHTML = JSON.stringify(totals);
        const avg = document.getElementById("avg")
        if (avg) {
            avg.innerHTML = (total / 12.0).toFixed(2).toString();
        }
        // console.log({ totals, partTimeTotals });
    
    
    
        return { totals, partTimeTotals }
    } else {
        var total1 = 0;
        var totals1: Totals = {
            "January": 0,
            "February": 0,
            "March": 0,
            "April": 0,
            "May": 0,
            "June": 0,
            "July": 0,
            "August": 0,
            "September": 0,
            "October": 0,
            "November": 0,
            "December": 0
        };
        var partTimeTotals1: Totals = {
            "January": 0,
            "February": 0,
            "March": 0,
            "April": 0,
            "May": 0,
            "June": 0,
            "July": 0,
            "August": 0,
            "September": 0,
            "October": 0,
            "November": 0,
            "December": 0
        };
    
        for (var i = 0; i < csvData.length; i++) {
            var currentline = csvData[i];
            if (currentline[4] == "2") { // Hilton East
                continue
            }
            if (currentline[3] == "1") { // Full Time Employee
                // total += parseInt(currentline[1]);
                if (!totals1[currentline[0]]) {
                    totals1[currentline[0]] = 0;
                }
                totals1[currentline[0]] += parseInt(currentline[3]);
                // console.log({ month: currentline[0], count: currentline })
                total1 += parseInt(currentline[3]);
            } else {
                // total += parseInt(currentline[1]);
                if (!partTimeTotals1[currentline[0]]) {
                    partTimeTotals1[currentline[0]] = 0;
                }
                partTimeTotals1[currentline[0]] += 1;
                // partTime += parseInt(currentline[3]);
                // console.log({ month: currentline[0], count: currentline[2], partTime: currentline[3] })
            }
    
    
        }
        // console.log({ partTimeTotals })
    
        const status1 = document.getElementById("status");
        if (status1) {
            status1.innerHTML = JSON.stringify(totals1) + "\n\n\n" + JSON.stringify(partTimeTotals1);
        }
        // document.getElementById("status").innerHTML = JSON.stringify(totals);
        
        
        
        
        var total2 = 0;
        
    
        for (var i = 0; i < csvData.length; i++) {
            var currentline = csvData[i];
            if (currentline[4] == "1") { // Angels in your Home
                continue
            }
            if (currentline[3] == "1") { // Full Time Employee
                // total += parseInt(currentline[1]);
                if (!totals1[currentline[0]]) {
                    totals1[currentline[0]] = 0;
                }
                totals1[currentline[0]] += parseInt(currentline[3]);
                // console.log({ month: currentline[0], count: currentline })
                total2 += parseInt(currentline[3]);
            } else {
                // total += parseInt(currentline[1]);
                if (!partTimeTotals1[currentline[0]]) {
                    partTimeTotals1[currentline[0]] = 0;
                }
                partTimeTotals1[currentline[0]] += 1;
                // partTime += parseInt(currentline[3]);
                // console.log({ month: currentline[0], count: currentline[2], partTime: currentline[3] })
            }
    
    
        }
        // console.log({ partTimeTotals })
    
        const status2 = document.getElementById("status");
        if (status2) {
            status2.innerHTML = JSON.stringify(totals1) + "\n\n\n" + JSON.stringify(partTimeTotals1);
        }
        // document.getElementById("status").innerHTML = JSON.stringify(totals);
        const avg = document.getElementById("avg")
        if (avg) {
            let avg1 = (total1 / 12.0).toFixed(2);
            alert(avg1);
            let avg2 = (total2 / 12.0).toFixed(2);
            alert(avg2);
            avg.innerHTML = (parseFloat(avg1) + parseFloat(avg2)).toFixed(2).toString();
            // avg.innerHTML = (total / 12.0).toFixed(2).toString();
            // avg.innerHTML = (total / 12.0).toFixed(2).toString();
        }
        // console.log({ totals, partTimeTotals });
    
    
    
        return { totals: totals1, partTimeTotals: partTimeTotals1 }
    
    }
    
}