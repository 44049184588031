import React from 'react'
import ApexCharts from 'apexcharts'
import { downloadURI } from '../utilities/download';
import { readRemoteCSVFile } from '../utilities/read-remote-csv';
import Radio from '../layout/radio';
interface Totals {
    [name: string] : number
}

export default function Single() {
    // Download a file form a url.
    
    
    
    function saveFile() {
        var start = new Date().getTime();
        var xmlHttp = new XMLHttpRequest();
        var uploadForm = document.getElementById("uploadForm");
        if (uploadForm !== null) {
            let uploadFormData = new FormData((uploadForm as HTMLFormElement));
            xmlHttp.open('POST', (process.env.NODE_ENV==="development"?"http://localhost:4567":"https://process-payroll-api.clearerc.com")+"/profile");
            // xmlHttp.open("GET", "/getAll", true);
            // xmlHttp.setRequestHeader("Cache-Control", "no-cache");
            xmlHttp.upload.addEventListener("progress", function (evt2) {
                const evt:any = evt2;
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total;
                //Do something with upload progress
                console.log(percentComplete);
              }
              console.log(evt);
              console.log(evt.currentTarget.responseText);
              const status = document.getElementById("status");
              if (status !== null) {
                status.innerHTML = evt.currentTarget.responseText;
                status.scrollTop = status.scrollHeight;
              }
              
            });
            xmlHttp.onreadystatechange = function () {
              if (xmlHttp.readyState == 4) {
                downloadURI("http://localhost:4567/download", "payroll.csv");
                readRemoteCSVFile("http://localhost:4567/download");
    
                const btn = document.getElementById("btn");
                if (btn !== null) {
                  btn.innerHTML = "Download Processed Payroll";
                }
              } else {
                console.log(xmlHttp.readyState);
              }
            };
            xmlHttp.send(uploadFormData);
        }
        
      }

      function download() {
        const btn = document.getElementById("btn");
                if (btn !== null) {
                  btn.innerHTML = "Processing...";
                }
        saveFile();
        /*.then(function(xhr) {
        console.log('File saved to ' + xhr.responseURL);
        document.getElementById("btn").innerHTML = "Download Processed Payroll";
        }).catch(function() {
        console.log('Error');
        });*/
      }
    return (

        <>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.8.1/flowbite.min.css"
          rel="stylesheet"
        />
        {/*
        This example requires some changes to your config:
        
        ```
        // tailwind.config.js
        module.exports = {
          // ...
          plugins: [
      // ...
      require('@tailwindcss/forms'),
          ],
        }
        ```
      */}
        {/*
        This example requires updating your template:
      
        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
        <div className="min-h-full">
          <div className="bg-indigo-600 pb-32">
            <nav className="border-b border-indigo-300 border-opacity-25 bg-indigo-600 lg:border-none">
              <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                  <div className="flex items-center px-2 lg:px-0">
                    <div className="flex-shrink-0">
                      <img className="block h-8" src="logo.png" alt="Your Company" />
                    </div>
                    <div className="hidden lg:ml-10 lg:block">
                      <div className="flex space-x-4">
                        {/* Current: "bg-indigo-700 text-white", Default: "text-white hover:bg-indigo-500 hover:bg-opacity-75" */}
                        <a
                          href="/dashboard"
                          className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                        >
                          Process Payroll
                        </a>
                        <a
                          href="/single"
                          className="bg-indigo-700 text-white block rounded-md py-2 px-3 text-base font-medium"
                          aria-current="page"
                        >
                          Process File
                        </a>
                        <a
                          href="/"
                          className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                        >
                          Logout
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex lg:hidden">
                    {/* Mobile menu button */}
                    <button
                      type="button"
                      className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                      aria-controls="mobile-menu"
                      aria-expanded="false"
                    >
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {/* Menu open: "hidden", Menu closed: "block" */}
                      <svg
                        className="block h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                        />
                      </svg>
                      {/* Menu open: "block", Menu closed: "hidden" */}
                      <svg
                        className="hidden h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              {/* Mobile menu, show/hide based on menu state. */}
              <div className="lg:hidden" id="mobile-menu">
                <div className="space-y-1 px-2 pb-3 pt-2">
                  {/* Current: "bg-indigo-700 text-white", Default: "text-white hover:bg-indigo-500 hover:bg-opacity-75" */}
                  <a
                    href="/dashboard"
                    className="text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium"
                  >
                    Process Payroll
                  </a>
                  <a
                    href="/single"
                    className="bg-indigo-700 text-white block rounded-md py-2 px-3 text-base font-medium"
                    aria-current="page"
                  >
                    Process File
                  </a>
                  <a
                    href="/"
                    className="text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium"
                  >
                    Logout
                  </a>
                </div>
              </div>
            </nav>
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl  tracking-widest text-white">
                  Process Files
                </h1>
              </div>
            </header>
          </div>
          <main className="-mt-32">
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 ">
                <form
                  action="/profile"
                  id="uploadForm"
                  method="post"
                  encType="multipart/form-data"
                >
                  {/* <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="multiple_files">Upload multiple files</label> */}
                  <input
                    name="avatar"
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    id="multiple_files"
                    type="file"
                    multiple
                  />
                </form>
                <div className='mt-5'>
                <Radio title="Select Format" options={[]}
                onChange={(option) => {
                  console.log(option);
                }}
                value={0}
                />
                </div>
                <div className="grid grid-cols-3 gap-5">
                  <div>
                    <button
                      type="button"
                      id="btn"
                      onClick={() => download()}
                      className="mt-5 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Download Processed Payroll
                      <svg
                        className="-mr-0.5 h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    <div
                      id="status"
                      className="h-96 border-gray-100 shadow border rounded mt-5 overflow-auto"
                    />
                  </div>
                  <div className="col-span-2  rounded border-gray-300">
                    <div className=" w-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
                      <div className="flex justify-between pb-4 mb-4 border-b border-gray-200 dark:border-gray-700">
                        <div className="flex items-center">
                          <div className="w-12 h-12 rounded-lg bg-gray-100 dark:bg-gray-700 flex items-center justify-center mr-3">
                            <svg
                              className="w-6 h-6 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              viewBox="0 0 20 19"
                            >
                              <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z" />
                              <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z" />
                            </svg>
                          </div>
                          <div>
                            <h5
                              className="leading-none text-2xl font-bold text-gray-900 dark:text-white pb-1"
                              id="avg"
                            >
                              0
                            </h5>
                            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                              Average full time employees
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="column-chart" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
      
    )
}
