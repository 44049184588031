import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import Menu from "../layout/menu";
import { HexColorPicker } from "react-colorful";
import generateQuarterlyChartOt from "./quarterly-chart-ot";

export default function Create() {
  const [val1, setVal1] = useState("10");
  const [val2, setVal2] = useState("20");
  const [val3, setVal3] = useState("30");
  const [val4, setVal4] = useState("40");
  const [val12, setVal12] = useState("50");
  const [val22, setVal22] = useState("60");
  const [val32, setVal32] = useState("70");
  const [val42, setVal42] = useState("80");
  const [title1, setTitle1] = useState("2019");
  const [title2, setTitle2] = useState("2020");
  const [color, setColor] = useState("#1A56DB");
  const [color2, setColor2] = useState("#FDBA8C");

  function createChart() {
    const totals = [];
    totals[1] = Number(val1);
    totals[2] = Number(val2);
    totals[3] = Number(val3);
    totals[4] = Number(val4);
    const partTimeTotals = [];
    partTimeTotals[1] = Number(val12);
    partTimeTotals[2] = Number(val22);
    partTimeTotals[3] = Number(val32);
    partTimeTotals[4] = Number(val42);

    const options = {
      totals,
      partTimeTotals,
      title1,
      title2,
      div: "column-chart1",
      color1: color,
      color2: color2,
    };
    generateQuarterlyChartOt(options);
  }

  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
          <Menu />
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl tracking-widest text-white">
                Create Chart
              </h1>
            </div>
          </header>
        </div>
        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6 min-h-96 ">
              <div className="grid grid-cols-3 gap-5">
                <div className="mt-5 col-span-3">
                  <div>
                    <label
                      htmlFor="title1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title 1
                    </label>
                    <input
                      value={title1}
                      onChange={(e) => setTitle1(e.target.value)}
                      type="text"
                      name="title1"
                      id="title1"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="title2"
                      className="mt-5 block text-sm font-medium text-gray-700"
                    >
                      Title 2
                    </label>
                    <input
                      value={title2}
                      onChange={(e) => setTitle2(e.target.value)}
                      type="text"
                      name="title2"
                      id="title2"
                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="grid grid-cols-4 gap-5">
                    <div>
                      <label
                        htmlFor="q1"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Q1 1
                      </label>
                      <input
                        value={val1}
                        onChange={(e) => setVal1(e.target.value)}
                        type="text"
                        name="q1"
                        id="q1"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="q2"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Q2 1
                      </label>
                      <input
                        value={val2}
                        onChange={(e) => setVal2(e.target.value)}
                        type="text"
                        name="q2"
                        id="q2"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="q3"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Q3 1
                      </label>
                      <input
                        value={val3}
                        onChange={(e) => setVal3(e.target.value)}
                        type="text"
                        name="q3"
                        id="q3"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="q4"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Q4 1
                      </label>
                      <input
                        value={val4}
                        onChange={(e) => setVal4(e.target.value)}
                        type="text"
                        name="q4"
                        id="q4"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-5">
                    <div>
                      <label
                        htmlFor="q1"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Q1 2
                      </label>
                      <input
                        value={val12}
                        onChange={(e) => setVal12(e.target.value)}
                        type="text"
                        name="q12"
                        id="q12"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="q22"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Q2 2
                      </label>
                      <input
                        value={val22}
                        onChange={(e) => setVal22(e.target.value)}
                        type="text"
                        name="q22"
                        id="q22"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="q32"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Q3 2
                      </label>
                      <input
                        value={val32}
                        onChange={(e) => setVal32(e.target.value)}
                        type="text"
                        name="q32"
                        id="q32"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="q42"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Q4 2
                      </label>
                      <input
                        value={val42}
                        onChange={(e) => setVal42(e.target.value)}
                        type="text"
                        name="q42"
                        id="q42"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>




                  <div className="grid grid-cols-2 mt-5">
                    <div>
                      <label
                        htmlFor="q42"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Color 1
                      </label>
                      <HexColorPicker color={color} onChange={setColor} />
                    </div>
                    <div>
                      <label
                        htmlFor="q42"
                        className="mt-5 block text-sm font-medium text-gray-700"
                      >
                        Color 2
                      </label>
                      <HexColorPicker color={color2} onChange={setColor2} />
                    </div>
                  
                  

                  </div>



                  

                  <button
                    onClick={createChart}
                    id="btnCompare"
                    className="mt-5 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                  >
                    Create Chart
                  </button>

                  <div className="p-2">
                    <div id="column-chart1" />

                    <div id="column-chart3" />
                    <div id="column-chart4" />
                    <div id="column-chart5" />
                    <div id="column-chart6" />
                    <div id="column-chart7" />
                    <div id="column-chart2" />

                    <div className="h-4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
