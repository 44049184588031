import React from "react";
import ReactApexChart from "react-apexcharts";

export class Gantt extends React.Component {
    constructor(props:any) {
      super(props);

      this.state = {
      
        series: [
          {
            name: 'EO235',
            data: [
              {
                x: ['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                y: [
                  new Date('2020-04-15').getTime(),
                  new Date('2021-07-01').getTime()
                ],
                fillColor: '#008FFB'
              },
              {
                x: ['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                y: [
                  new Date('2021-07-12').getTime(),
                  new Date('2022-03-01').getTime()
                ],
                fillColor: '#008FFB'
              },
              {
                x: ['Masking','Baltimore City', 'Apr 3, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                y: [
                  new Date('2020-04-03').getTime(),
                  new Date('2021-07-01').getTime()
                ],
                fillColor: '#00E396'
              },
              {
                x: ['Masking','Baltimore City', 'Apr 3, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                y: [
                  new Date('2021-07-12').getTime(),
                  new Date('2022-03-01').getTime()
                ],
                fillColor: '#00E396'
              },
              
              {
                x: ['50% Occupancy', 'Maryland State', 'Jun 10, 2020 - May 15, 2021'],
                y: [
                  new Date('2020-06-10').getTime(),
                  new Date('2021-05-15').getTime()
                ],
                fillColor: '#775DD0'
              },
              {
                x: ['25% Occupancy','Baltimore City', 'Aug 7, 2020 - May 15, 2021'],
                y: [
                  new Date('2020-08-07').getTime(),
                  new Date('2021-05-15').getTime()
                ],
                fillColor: '#FEB019'
              },
            ]
          },
      
        ],
        options: {
            series: [
                {
                  name: 'EO235',
                  data: [
                    {
                      x: ['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                      y: [
                        new Date('2020-04-15').getTime(),
                        new Date('2021-07-01').getTime()
                      ],
                      fillColor: '#008FFB'
                    },
                    {
                      x: ['Masking','Maryland State', 'Apr 15, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                      y: [
                        new Date('2021-07-12').getTime(),
                        new Date('2022-03-01').getTime()
                      ],
                      fillColor: '#008FFB'
                    },
                    {
                      x: ['Masking','Baltimore City', 'Apr 3, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                      y: [
                        new Date('2020-04-03').getTime(),
                        new Date('2021-07-01').getTime()
                      ],
                      fillColor: '#00E396'
                    },
                    {
                      x: ['Masking','Baltimore City', 'Apr 3, 2020 - Jul 01, 2021', 'Jul 12, 2021 - Mar 01, 2022'],
                      y: [
                        new Date('2021-07-12').getTime(),
                        new Date('2022-03-01').getTime()
                      ],
                      fillColor: '#00E396'
                    },
                    
                    {
                      x: ['50% Occupancy', 'Maryland State', 'Jun 10, 2020 - May 15, 2021'],
                      y: [
                        new Date('2020-06-10').getTime(),
                        new Date('2021-05-15').getTime()
                      ],
                      fillColor: '#775DD0'
                    },
                    {
                      x: ['25% Occupancy','Baltimore City', 'Aug 7, 2020 - May 15, 2021'],
                      y: [
                        new Date('2020-08-07').getTime(),
                        new Date('2021-05-15').getTime()
                      ],
                      fillColor: '#FEB019'
                    },
                  //   {
                  //     x: 'Validation',
                  //     y: [
                  //       new Date('2019-03-11').getTime(),
                  //       new Date('2019-03-16').getTime()
                  //     ]
                  //   },
                  //   {
                  //     x: 'Design',
                  //     y: [
                  //       new Date('2019-03-01').getTime(),
                  //       new Date('2019-03-03').getTime()
                  //     ],
                  //   }
                  ]
                },
              //   {
              //     name: 'Masking - Baltimore City',
              //     data: [
              //         {
              //             x: 'Masking - Baltimore City',
              //             y: [
              //               new Date('2019-03-01').getTime(),
              //               new Date('2019-03-07').getTime()
              //             ]
              //           },
              //       {
              //         x: 'Design',
              //         y: [
              //           new Date('2019-03-02').getTime(),
              //           new Date('2019-03-05').getTime()
              //         ]
              //       },
              //       {
              //         x: 'Test',
              //         y: [
              //           new Date('2019-03-06').getTime(),
              //           new Date('2019-03-16').getTime()
              //         ],
                      
              //       },
              //       {
              //         x: 'Code',
              //         y: [
              //           new Date('2019-03-03').getTime(),
              //           new Date('2019-03-07').getTime()
              //         ]
              //       },
              //       {
              //         x: 'Deployment',
              //         y: [
              //           new Date('2019-03-20').getTime(),
              //           new Date('2019-03-22').getTime()
              //         ]
              //       },
              //       {
              //         x: 'Design',
              //         y: [
              //           new Date('2019-03-10').getTime(),
              //           new Date('2019-03-16').getTime()
              //         ]
              //       }
              //     ]
              //   },
              //   {
              //     name: '50% Occupancy - Marland State',
              //     data: [
              //       {
              //         x: 'Code',
              //         y: [
              //           new Date('2019-03-10').getTime(),
              //           new Date('2019-03-17').getTime()
              //         ]
              //       },
              //       {
              //         x: 'Validation',
              //         y: [
              //           new Date('2019-03-05').getTime(),
              //           new Date('2019-03-09').getTime()
              //         ],
                      
              //       },
              //     ]
              //   }
              ],
          chart: {
            height: 450,
            type: 'rangeBar',
            offsetX: 60
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '80%',
              borderRadius: 8,
            }
          },
          xaxis: {
            labels: {
                show: false,
              formatter: function (value:any) {
                return new Date(value).toLocaleDateString('en-US');;
              },

            },
            tickAmount: 12,
            
          },
          stroke: {
            width: 0
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: "vertical",
              shadeIntensity: 0.3,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 0.5,
              stops: [0, 100],
              colorStops: []
            }
          },
          annotations: {
            xaxis: [
              {
                x: new Date('1 April 2020').getTime(),
                borderColor: '#775DD0',
                label: {
                  style: {
                    color: '#000',
                  },
                  text: 'Q2 2020'
                }
              },
              {
                x: new Date('1 July 2020').getTime(),
                borderColor: '#775DD0',
                label: {
                  style: {
                    color: '#000',
                  },
                  text: 'Q3 2020'
                }
              },
              {
                x: new Date('1 October 2020').getTime(),
                borderColor: '#775DD0',
                label: {
                  style: {
                    color: '#000',
                  },
                  text: 'Q4 2020'
                }
              },
              {
                x: new Date('1 January 2021').getTime(),
                borderColor: '#775DD0',
                label: {
                  style: {
                    color: '#000',
                  },
                  text: 'Q1 2021'
                }
              },
              {
                x: new Date('1 April 2021').getTime(),
                borderColor: '#775DD0',
                label: {
                  style: {
                    color: '#000',
                  },
                  text: 'Q2 2021'
                }
              },
              {
                x: new Date('1 July 2021').getTime(),
                borderColor: '#775DD0',
                label: {
                  style: {
                    color: '#000',
                  },
                  text: 'Q3 2021'
                }
              },
              {
                x: new Date('1 October 2021').getTime(),
                borderColor: '#775DD0',
                label: {
                  style: {
                    color: '#000',
                  },
                  text: 'Q4 2021'
                }
              },
              {
                x: new Date('1 January 2022').getTime(),
                borderColor: '#775DD0',
                label: {
                  style: {
                    color: '#000',
                  },
                  text: 'Q1 2022'
                }
              },
            ]
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left'
          },
          yaxis: {
            labels: {
            style: {
                fontSize: '11px',
            }
        }
          },
          grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },   
            yaxis: {
                lines: {
                    show: false
                }
            },  
            row: {
                colors: undefined,
                opacity: 0.5
            },  
            column: {
                colors: undefined,
                opacity: 0.5
            },  
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },  
        }
        },
      
      
      };
    }

  

    render() {
      return (
        

  <div id="chart" className="bg-white">
    {/* <pre>
        {JSON.stringify(
            {
            // series: (this.state as any).series,
            options: (this.state as any).options,
            },
            null,
            2
        )}
    </pre> */}
    <img src={"https://quickchart.io/apex-charts/render?width=1500&height=450&config="+encodeURIComponent(JSON.stringify((this.state as any).options))} alt="chart"/>
<ReactApexChart options={(this.state as any).options} series={(this.state as any).series} type="rangeBar" height={450} />
</div>
      );
    }
    }